const configs = {
	// 正式
	url3: 'videonewsfeed.com',
	allUrl3: 'https://api.videonewsfeed.com',

	// 线上2
	url1: 'maboshop.com',
	allUrl1: 'https://api.maboshop.com',


	// 线上3
	url: 'videonewsfeeds.com',
	allUrl: 'https://api.videonewsfeeds.com',

	// 测试
	devUrl: '192.168.1.14:9001',
	allDevUrl: 'http://192.168.1.14:9001',

	publicImg: 'https://wallpaper-x.oss-ap-southeast-5.aliyuncs.com/'

}

export default configs