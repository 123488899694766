import configs from "@/common/config.js"
export default {
	//接口基地址

	//代理及基地址
	// Millia: process.env.NODE_ENV == 'development' ? location.protocol + '//' + location.host + '/Apis' : process.env
	// 	.VUE_APP_BASE_URL,
	Millia: 'https://api.videonewsfeed.com',

	MilliaOther: process.env.NODE_ENV == 'development' ? location.protocol + '//' + location.host +
		'/MilliaOtherApi' : 'http://192.168.1.110:9002',

	//后台接口

	//列表接口
	SAVE_SIGN: '/videos/v1/page',
	//详情接口 +ID
	SAVE_DETAIL: '/videos/v1/detail/',
	//详情推荐接口
	SAVE_RECOM: '/videos/v1/recommend',
	//横版详情接口
	HORIZ_DETA: '/videos/v1',
	//其他接口
	GET_COURSEINFO: '/xxxx/other/otherInfo',
	// 分组接口
	GROOUP_VAL: '/visitors/v1/info',
	// 新访客推荐列表
	NEW_USER: '/videos/v1/recommend/newer',
	NEW_USERV2: '/videos/v2/recommend/newer',


	// 新闻信息 /wallpaper/articles/v1/{id}
	NEWS_DETAIL: '/wallpaper/articles/v1/',
	// 新闻列表 /wallpaper/articles/v1/page
	NEWS_LIST: '/wallpaper/images/v1/page',

}