import axios from 'axios'
import {
	showToast
} from 'vant';
// 创建一个 axios 实例
const milliaAxios = axios.create({
	timeout: 6000, // 请求超时时间毫秒
	withCredentials: false, // 异步请求携带cookie
	//headers: {
	//	// 设置后端需要的传参类型
	//	'Content-Type': 'application/json',
	//	'token': 'your token',
	//	'X-Requested-With': 'XMLHttpRequest',
	//},
})

//拦截器 请求拦截 
milliaAxios.interceptors.request.use(config => {
	const country = localStorage.getItem('CONTRY')
	if (config.method !== "get") {
		config.data.country = country || 'in'
	}
	config.headers['X-Country'] = country || 'in'
	// console.log('获取请求休息休息细腻系', config.headers)
	return config;
}, error => {
	console.log(error)
	return Promise.reject(error);
});

//拦截器 响应拦截
milliaAxios.interceptors.response.use(response => {
	const status = response.status
	//根据后端约定状态判断执行 这里是判断状态移除token

	if (status) {
		const code = response.data.code
		if (code == 400) {
			// 前端问题
			console.log(response.data.msg)
			showToast(response.data.msg)
		} else if (code == 500) {
			// 服务器问题
			console.log(response.data.msg)
			showToast(response.data.msg)
		} else {
			return response.data.data
		}

	}
	//根据后端约定状态判断执行 结束

}, error => {
	// 对响应错误做点什么
	let errorMessage = 'An unknown error occurred';
	if (error.response) {
		// 服务器响应错误
		const status = error.response.status;
		switch (status) {
			case 400:
				errorMessage = 'Bad Request';
				break;
			case 401:
				errorMessage = 'Unauthorized';
				break;
			case 403:
				errorMessage = 'Forbidden';
				break;
			case 404:
				errorMessage = 'Not Found';
				break;
			case 500:
				errorMessage = 'Internal Server Error';
				break;
			case 502:
				errorMessage = 'Internal Server Error 502';
				break;
			default:
				errorMessage = `Error: ${status}`;
		}
	} else if (error.request) {
		// 请求已经发出，但没有响应
		errorMessage = 'No response received from the server';
	} else {
		// 请求过程中发生了错误
		errorMessage = error.message;
	}
	console.error(errorMessage);
	return Promise.reject(new Error(errorMessage));
});

export default milliaAxios