import {
	createApp
} from 'vue'
import VueYtframe from "vue3-ytframe"
import App from './App.vue'
import {
	createPinia
} from 'pinia';
const pinia = createPinia();

// 图片懒加载
import "vant/lib/index.css";

import VueLazyLoad from 'vue3-lazy'




// eslint-disable-next-line import/no-unresolved,n/no-missing-import
import VuePlyr from '@skjnldsv/vue-plyr'
// eslint-disable-next-line import/no-unresolved,n/no-missing-import
import '@skjnldsv/vue-plyr/dist/vue-plyr.css'


import {
	Swipe,
	SwipeItem,
	Toast,
	Button
	// Waterfall 
} from 'vant';

// SDK挂载至全局


import router from './router'

// import Ads from 'vue-google-adsense' 
const app = createApp(App)

app.use(Swipe).use(Button).use(VuePlyr, {
	plyr: {
		autoplay: true,
		clickToPlay: true
	}
}).use(SwipeItem).use(Toast).use(router).use(VueYtframe).use(VueLazyLoad, {
	loading: require('@/assets/img/picLoading.png'), // 图片加载时默认图片
	error: require('@/assets/img/picFail.png') // 图片加载失败时默认图片
	// 还可以有其他配置
});


/*全局引入引入axios*/
import axios from "@/api/axios.js"
app.config.globalProperties.$Axios = axios;





//全局引入api
import api from '@/api/index.js';
app.config.globalProperties.$Apis = api
app.use(pinia)
app.mount('#app')