import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'

import {
	useMainStore
} from '@/store';


const routes = [{
		path: '/',
		name: 'home',
		component: () => import('../views/home.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/detail/:id',
		name: 'detail',
		component: () => import('../views/detail.vue')
	},

	{
		path: '/detail',
		name: 'detailNone',
		component: () => import('../views/detail.vue')
	},
	// {
	// 	path: '/verticalIndex',
	// 	name: 'verticalIndex',
	// 	component: verticalIndex
	// },
	// 瀑布流详情
	{
		path: '/shortTwoDetal/:id',
		name: 'shortTwoDetal',
		component: () => import('../views/vertical/detail.vue')
	},
	{
		path: '/shortTwoDetal',
		name: 'shortTwoDetalNone',
		component: () => import('../views/vertical/detail.vue')
	},
	// 双瀑布流
	{
		path: '/shortTwo',
		name: 'shortTwo',
		component: () => import('../views/vertical/index.vue')
	},
	// 单瀑布流
	{
		path: '/verticalTwoIndex',
		name: 'verticalTwoIndex',
		component: () => import('../views/horizontal/index.vue')
	},
	// 新闻页面
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/news/index.vue')
	},
	// 新闻页面
	{
		path: '/newsDetal',
		name: 'newsDetal',
		component: () => import('../views/news/newDetail.vue')
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('../views/test.vue')
	}

]
const router = createRouter({
	// history: createWebHistory(),
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				left: 0,
				top: 0
			};
		}
	}
});


// 添加全局前置守卫
router.beforeEach((to, from, next) => {
	// 在路由跳转前执行的逻辑
	const store = useMainStore();
	if (from.name === 'detail') {
		console.log('返回来了')
		// alert('我回来了')
		store.setReturnedFromNewPage(true);
	}
	console.log('即将跳转', to.name)
	// alert(from.name)
	next(); // 必须调用该方法来resolve这个钩子
});

// 添加全局后置钩子
router.afterEach((to, from) => {
	// 在路由跳转后执行的逻辑
	// alert(from.name)
	console.log('已经跳转：', to.path, from.name);
});


export default router